<template>
	<div
		:class="$style.noAccess"
		v-if="!access"
	>У вас нет доступа в данный раздел
	</div>
	<div
		:class="{[$style.container]:1,[$style.loading]:loading}"
		v-else
	>
		<NavTabs
			:data="navItems"
			active="Персонал"
		/>
		<div
			:class="$style.header"
			id="infoTable_personal"
		>
			<h2 :class="$style.name">Персонал</h2>
			<div :class="$style.header__row">
				<a
					href="#"
					@click.prevent="openModal()"
					:class="$style.personal__add"
				>Добавить сотрудника</a>
				<OrderSearch
					:class="$style.header__search"
					@search="filterStr = $event"
				/>
				<PersonalAdd
					:class="$style.header__sort"
					:label="sort.label"
					:list="popUp"
					@setSelected="setSort"
				/>
			</div>
		</div>
		<div :class="$style.body">
			<div
				:class="$style.empty"
				v-if="errorMsg"
			>{{ errorMsg }}
			</div>
			<PersonalRow
				v-if="personalRow"
				:data="personalRow"
				@updateList="loadEmployeesList"
				@openModal="openModal"
				@setError="setError"
				@setLoading="setLoading"
			/>
		</div>
		<Modal
			v-if="isModalVisible"
			@close="closeModal"
		>
			<PersonalModal
				:empId="this.empId"
				:jobsList="jobsList"
				@updateList="loadEmployeesList"
			/>
		</Modal>
	</div>
</template>

<script>
import debounce from "lodash/debounce";
import qs from 'qs';
import axios from 'axios';
import {mapState} from "vuex";

export default {
	components: {
		NavTabs: () => import('@/components/block/NavTabs'),
		Modal: () => import('@/components/block/Modal'),
		OrderSearch: () => import('@/components/block/Rest/OrderSearch.vue'),
		PersonalAdd: () => import('@/components/block/Rest/PersonalAdd'),
		PersonalRow: () => import('@/components/block/Rest/PersonalRow'),
		PersonalModal: () => import('@/components/block/Rest/PersonalModal'),
	},
	data()
	{
		return {
			isModalVisible: false,
			empId: false,
			navItems: [
				{
					name: 'Персонал',
					target: '#infoTable_personal'
				},
				{
					name: 'Привлечь сотрудников',
					target: '#infoTable_map'
				},
			],
			popUp: [{
				value: 'id_asc',
				label: 'ID по возрастанию'
			}, {
				value: 'id_desc',
				label: 'ID по убыванию'
			}, {
				value: 'fio_asc',
				label: 'ФИО по возрастанию'
			}, {
				value: 'fio_desc',
				label: 'ФИО по убыванию'
			}],
			jobsList: false,
			personalRow: false,
			showList: false,
			errorMsg: false,
			loading: false,
			sort: {value: 'id_asc', label: 'ID по возрастанию'},
			filterStr: '',
		}
	},
	computed: {
		...mapState(['currentOwnerRestaurant']),
		access()
		{
			return this.$store.state.user.IS_RST_OWNER || this.$store.state.user.IS_RST_MANAGER;
		},
	},
	watch: {
		filterStr: {
			handler: debounce(function ()
			{
				this.loadEmployeesList();
			}, 700),
			deep: true
		},
	},
	methods: {
		openModal(id = false)
		{
			this.empId = id;
			this.isModalVisible = true;
		},

		closeModal()
		{
			this.isModalVisible = false;
		},

		update()
		{
			this.showList = false;
		},

		setError(msg)
		{
			this.errorMsg = msg;
		},

		setLoading(val)
		{
			this.loading = val;
		},

		setSort(val)
		{
			this.sort.value = val.value;
			this.sort.label = val.label;
			this.loadEmployeesList();
		},

		async loadEmployeesList()
		{
			this.loading = true;
			let data = qs.stringify({
				sort: this.sort.value,
				filterStr: this.filterStr ? this.filterStr : '',
				currentOwnerRestaurant: this.currentOwnerRestaurant
			});
			let result = await axios.post('/lk/getRestaurantEmployees/', data);
			this.loading = false;

			this.personalRow = false;
			this.errorMsg = false;
			if (result.data.success)
			{
				this.personalRow = result.data.employees;
			} else
			{
				this.errorMsg = result.data.error;
			}
		},
		async getAvailableJobs()
		{
			let data = qs.stringify({
				id: this.empId,
				currentOwnerRestaurant: this.currentOwnerRestaurant
			});
			let result = await axios.post('/lk/getAvailableJobs/', data);
			if (result.data.success)
			{
				this.jobsList = result.data.jobs;
			}
		},
	},
	mounted()
	{
		if (!this.access) return;

		this.loadEmployeesList();
		this.getAvailableJobs();
	}
}
</script>

<style
	lang="scss"
	module
>
$black: #3D4248;
$green: #66BE6D;
$grey: #979797;
$lightgray: #F8F8F8;

.noAccess
{
	color: red;
	margin: 20px;
	font-size: 22px;
}

.container
{
	padding: 40px 72px;

	&.loading
	{
		opacity: 0.5;
		pointer-events: none;
	}
}

.personal__add
{
	margin-right: 32px;
	padding: 14px 24px;
	color: white;
	font-size: 14px;
	font-weight: 700;
	background: $green;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.header
{
	&__row
	{
		display: flex;
		margin-top: 24px;
	}

	&__search
	{
		display: flex;
		flex-grow: 1;
		margin-right: 32px;
	}

	&__sort
	{
		min-width: 213px;
	}
}

.body
{
	margin-top: 24px;
}

.name
{
	font-size: 24px;
	color: $black;
	font-weight: 700;
}

.empty
{
	font-size: 18px;
	font-weight: 400;
	margin-bottom: 20px;
	color: red;
}
</style>